<template>
  <OrdersForm
    @validate="validate"
    :order="order"
    :valid="isFormValid"
    :type="'create'"
    ref="form"
  />
</template>

<script>
import beforeLeaveRouteModal from '@/mixins/beforeLeaveRouteModal.js'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'
export default {
  components: {
    OrdersForm: () => import('./OrdersForm'),
  },
  mixins: [beforeLeaveRouteModal, checkProfileRoutePermission],
  data: () => ({
    isFormValid: true,
    askToLeave: true,
    order: {
      status: true,
      type: 'order',
      profile_id: null,
      image_profile: null,
      name: null,
      birthday: null,
      contact: null,
      email: null,
      rg: null,
      rg_issuer: null,
      cpf_cnpj: null,
      cnh: null,
      voter_registration: null,
      cep: null,
      address: null,
      district: null,
      number: null,
      uf: null,
      city: null,
    },
  }),
  methods: {
    validate(value) {
      this.isFormValid = value
    },
    createOrder() {
      const formData = new FormData()
      const orderKeys = Object.keys(this.order)
      const orderValues = Object.values(this.order)

      for (let i = 0; i < orderKeys.length; i++) {
        if (orderValues[i] !== null) {
          formData.append(orderKeys[i], orderValues[i])
        }
      }

      this.$refs.form.validate()

      if (this.isFormValid) {
        this.askToLeave = false
        this.$api
          .post('orders', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(() => {
            this.$store.commit('setModal', {
              show: true,
              component: 'success',
              text: 'Pedido cadastrado com sucesso',
              confirm: () => {
                this.$router.push({ name: 'orders' })
              },
            })
          })
          .catch(() => {
            this.$store.commit('setModal', {
              show: true,
              component: 'error',
              text: 'Ops! Falha ao tentar cadastrar este pedido',
              caption: 'Verifique se os campos estão preenchidos corretamente.',
              confirm: () => {
                this.$store.commit('setModal', {
                  show: false,
                })
              },
            })
          })
      } else {
        this.$store.commit('setModal', {
          show: true,
          component: 'error',
          text: 'Ops! Falha ao tentar cadastrar este pedido',
          caption: 'Verifique se os campos estão preenchidos corretamente.',
          confirm: () => {
            this.$store.commit('setModal', {
              show: false,
            })
          },
        })
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.askToLeave) {
      this.openModal(
        () => {
          next()
        }, // Caso o modal seja confirmado
        () => {
          next(false)
        } // Caso o modal seja negado
      )
    } else {
      next()
    }
  },
}
</script>
